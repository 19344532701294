<template>
  <div class="category-selector">
    <v-select
        v-model="propValue"
        :placeholder="placeholder"
        :options="options"
        autowidth
        :multiple="multiple"
        label="name"
    >
    </v-select>
  </div>
</template>

<script>
import ApiService from "../../api";
import vSelect from 'vue-select'

export default {
  name: "CategorySelector",

  components: {
    'v-select': vSelect,
  },

  props: {
    value: {
      default: null
    },
    placeholder: {
      type: String,
      default: 'Kategorien wählen...'
    },
    type: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      propValue: null,
      options: []
    }
  },

  created() {
    this.fetchCategories();

    if (this.value !== null) {
      this.propValue = this.value;
    }
  },

  methods: {
    fetchCategories() {
      let filter = '';

      if (this.type !== null) {
        filter = '?filter[0][field]=usable_on' +
            '&filter[0][value]=' + this.type;
      }

      ApiService.get('categories' + filter).then(response => {
        if (response.data.status !== 'success') {
          return;
        }

        this.options = response.data.result;
      });
    },

    emitPropValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },

  watch: {
    propValue: {
      handler: 'emitPropValue'
    },
    value() {
      this.propValue = this.value;
    }
  }
}
</script>

<style lang="scss">

</style>