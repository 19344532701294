<template>
    <div class="product-details" v-if="fetched && data">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/3 w-full">
                <vx-card title="Produkt Details" >
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row mt-0">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.active" class="ml-0">Produkt ist aktiv</vs-checkbox>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col w-full sm:w-1/2">
                                    <vs-input class="w-full" label="Order-Nr. *" data-vv-as="Order-Nr."
                                              name="ordernumber"
                                              v-model="data.ordernumber" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('ordernumber')">{{ errors.first('ordernumber') }}</span>
                                </div>




                            </div>
                            <div class="vx-col lg:w-full w-full mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-select label="Art des Produktes" v-model="data.article_type_id" class="w-full">
                                        <vs-select-item :key="item.id" :value="item.id" :text="item.name"
                                                        v-for="(item, index) in articleTypes"/>
                                    </vs-select>
                                </div>
                            </div>
                            <div class="vx-row mt-6">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.is_hour_unit" class="ml-0" @change="onChangeHourUnit">
                                        Produkt wird über den Stundensatz abgerechnet
                                    </vs-checkbox>
                                </div>
                            </div>

                            <div class="vx-row mt-4 mb-2">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.discountable" class="ml-0" @change="onChangeHourUnit">
                                        Produkt ist rabattierbar.
                                    </vs-checkbox>
                                </div>
                            </div>

                            <div class="vx-row mt-4 mb-2">
                                <div class="vx-col w-full sm:w-1/2">
                                    <vs-input class="w-full" label="Abnahmestaffeln (Schritte) *" data-vv-as="Abnahmestaffeln"
                                              name="amount_steps"
                                              v-model="data.amount_steps" type="number" min="1" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('amount_steps')">{{ errors.first('amount_steps') }}</span>
                                </div>

                                <div class="vx-col w-full mt-2 sm:mt-0 sm:w-1/2">
                                    <vs-input class="w-full" label="Min. Abnahmemenge *" min="1" data-vv-as="Min. Menge" name="min_amount"
                                              v-model="data.min_amount" type="number" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('min_amount')">{{ errors.first('min_amount') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4 mb-2" v-if="data.min_amount < data.amount_steps">
                                <vs-alert color="#ff0000" :active="true" icon="error" class="mb-4" >
                                    Es macht nur sinn, wenn die Min. Abnahmemenge gleich oder größer als die Staffel ist.
                                </vs-alert>
                            </div>

                            <div class="vx-row mt-4 mb-2" v-if="data.min_amount != data.amount_steps">
                                <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4" >
                                   Hinweis: Bitte prüfe, ob die Min. Abnahmemenge ein vielfaches der Staffel ist.
                                </vs-alert>
                            </div>


                            <div class="vx-col lg:w-full w-full mt-3">
                                <div class="vx-row">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label" v-if="!data.is_hour_unit">Einzelpreis</label>
                                        <label class="vs-input--label" v-if="data.is_hour_unit">Stundensatz</label>
                                        <currency-input class="vs-input--input normal"
                                                        v-model="data.price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label">Mindestpreis</label>
                                        <currency-input class="vs-input--input normal"
                                                        v-model="data.min_price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label">Einkaufspreis</label>
                                        <currency-input class="vs-input--input normal"
                                                        v-model="data.purchase_price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col w-full sm:w-1/2">
                                        <vs-select label="MwSt." v-model="data.tax" class="w-full">
                                            <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                            v-for="(item, index) in taxOptions"/>
                                        </vs-select>
                                    </div>
                                    <div class="vx-col w-full sm:w-1/2">
                                        <vs-select label="Einheit" v-model="data.unit" class="w-full">
                                            <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                            v-for="(item, index) in unitOptions"/>
                                        </vs-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
                <vx-card title="Vertragsartikel" class="mt-5" >
                    <div class="vx-col lg:w-full w-full mt-5">
                        <div class="vx-col lg:w-full">

                            <vs-checkbox v-model="data.require_contract_condition" class="ml-0 mt-2">Produkt ist ein Vertragsartikel und setzt eine Vertragskondition im Angebot voraus.</vs-checkbox>

                            <vs-select label="Standard Vertragskondition (lässt sich aber je Angebot ändern)" v-model="data.default_contract_condition_id" class="w-full mt-2">
                                <vs-select-item :key="item.id" :value="item.id" :text="item.working_title"
                                                v-for="(item, index) in contractConditions"/>
                            </vs-select>
                        </div>
                    </div>
                </vx-card>

              <vx-card title="Lizenzartikel" class="mt-5" >
                <div class="vx-col lg:w-full w-full mt-5">
                  <div class="vx-col lg:w-full">

                    <vs-checkbox v-model="data.is_licence_product" class="ml-0 mt-2">Dieser Artikel ist ein Lizenzartikel</vs-checkbox>

                    <vs-input class="w-full mt-2" label="Technischer Name (Plugin/Addon)" v-model="data.licence_technical_name" type="text" />
                    <vs-alert v-show="data.is_licence_product && (!data.licence_technical_name || data.licence_technical_name.length === 0)" color="warning" icon="info">
                     Technischen Namen benötigt !!!!
                    </vs-alert>

                    <label  class="mt-3 block">In welchen Plänen ist diese Lizenz bereits inkludiert (ohne Kosten)?</label>
                    <v-select
                        v-model="data.licence_included_plans"
                        placeholder="Bitte auswählen"
                        :options="licenceIncludePlanOptions"
                        autowidth
                        multiple
                        label="name"
                    >
                    </v-select>
                  </div>
                </div>
              </vx-card>
                <vx-card title="Kategorien und Tags" class="mt-5" >
                    <div class="vx-col w-full mt-5">
                        <h4 class="mt-5">Kategorien und Tags</h4>
                        <div class="vx-row">
                            <div class="vx-col w-1/2">
                                <category-selector v-model="data.categories" type="product"></category-selector>
                            </div>
                            <div class="vx-col w-1/2">
                                <tag-selector v-model="data.tags" type="product"></tag-selector>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/3 w-full">
                <vx-card title="Produkt Beschreibung (DE)" class="h-full">

                    <div style="float:right; margin-top: -38px;">
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 30px" viewBox="0 0 5 3"><path d="M0 0h5v3H0z"/><path fill="#D00" d="M0 1h5v2H0z"/><path fill="#FFCE00" d="M0 2h5v1H0z"/></svg>
                    </div>
                    <div class="vx-row">

                        <div class="vx-col w-full mb-4">
                            <vs-input class="w-full" label="Name *" data-vv-as="Name" name="name"
                                      v-model="data.name" type="text" v-validate="'required'"/>
                            <span class="text-danger text-sm" v-if="errors.has('name')">{{ errors.first('name') }}</span>
                        </div>

                        <div class="vx-col w-full">
                            <label class="vs-input--label">Beschreibung (Für Rechnung + Gutschrift)</label>
                            <vue-editor name="description" v-model="data.description" :editorToolbar="toolbar"
                                        data-vv-as="Beschreibung"/>
                            <span class="text-danger text-sm" v-if="errors.has('description')">{{ errors.first('description') }}</span>
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Beschreibung fürs Angebot/AB (<b>Optional</b> - Wenn nicht gefüllt wird die obere Beschreibung verwendet)</label>
                            <vue-editor name="offer_description" v-model="data.offer_description" :editorToolbar="toolbar"
                                        data-vv-as="Angebots Beschreibung"/>
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Interne Beschreibung</label>
                            <vue-editor v-model="data.internal_description" />
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Interne Workflow-Beschreibung</label>
                            <vue-editor v-model="data.internal_workflow_description" />
                        </div>

                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/3 w-full">
                <vx-card title="Product description (EN)" class="h-full">

                    <div style="float:right; margin-top: -38px;">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"  style="width: 30px">
                            <clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath>
                            <clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath>
                            <g clip-path="url(#a)"><path d="M0 0v30h60V0z" fill="#012169"/>
                                <path d="M0 0l60 30m0-30L0 30" stroke="#fff" stroke-width="6"/>
                                <path d="M0 0l60 30m0-30L0 30" clip-path="url(#b)" stroke="#C8102E" stroke-width="4"/>
                                <path d="M30 0v30M0 15h60" stroke="#fff" stroke-width="10"/>
                                <path d="M30 0v30M0 15h60" stroke="#C8102E" stroke-width="6"/>
                            </g>
                        </svg>
                    </div>

                    <div class="vx-row">


                        <div class="vx-col w-full mb-4">
                            <vs-input class="w-full" label="Name *" data-vv-as="Name" name="name"
                                      v-model="data.name_en" type="text" v-validate="'required'"/>
                            <span class="text-danger text-sm" v-if="errors.has('name')">{{ errors.first('name') }}</span>
                        </div>

                        <div class="vx-col w-full">
                            <label class="vs-input--label">Beschreibung (Für Rechnung + Gutschrift)</label>
                            <vue-editor name="description" v-model="data.description_en" :editorToolbar="toolbar"
                                        data-vv-as="Beschreibung"/>
                            <span class="text-danger text-sm" v-if="errors.has('description')">{{ errors.first('description') }}</span>
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Beschreibung fürs Angebot/AB (<b>Optional</b> - Wenn nicht gefüllt wird die obere Beschreibung verwendet)</label>
                            <vue-editor name="offer_description" v-model="data.offer_description_en" :editorToolbar="toolbar"
                                        data-vv-as="Angebots Beschreibung"/>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'products-and-services'}">Abbrechen
            </vs-button>
            <vs-button class="ml-3 mb-4" @click="saveArticle">Speichern</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import staticOptions from "../../../mixins/static/options";
    import vSelect from 'vue-select'
    import CategorySelector from "../../../components/search/CategorySelector";
    import TagSelector from "../../../components/search/TagSelector";

    import { Quill } from "vue2-editor";

    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

    export default {
        components: {TagSelector, CategorySelector, vSelect},
        data() {
            return {
                data: null,
                fetched: false,
                taxOptions: staticOptions.tax,
                articleTypes: [],
                contractConditions: [],
                licenceIncludePlanOptions: staticOptions.licenceIncludePlan,
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'color': []}, "code-block"],
                ],
            }
        },
        mounted() {
            this.fetchArticleTypes();
            this.fetchContractConditions();
            this.fetchArticleData();
        },
        computed: {
            unitOptions() {
                if (!this.data.is_hour_unit) {
                    return staticOptions.units.filter(item => !item.hour_unit);
                }

                return staticOptions.units.filter(item => item.hour_unit);
            }
        },
        methods: {
            onChangeHourUnit() {
                if (!this.data.is_hour_unit) {
                    return this.data.unit = staticOptions.units.find(item => !item.hour_unit).value;
                }

                return this.data.unit = staticOptions.units.find(item => item.hour_unit).value;
            },
            fetchArticleTypes() {
                ApiService.get('article_types')
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.articleTypes = response.data.result;
                        }
                    })
            },
            fetchContractConditions() {
                ApiService.get('contract_condition')
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.contractConditions = response.data.result;
                        }
                    })
            },
            fetchArticleData() {
                ApiService.get('articles/' + this.$route.params.id)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fetched = true;
                            this.data = response.data.result;
                        } else {
                            this.$router.push({name: 'customers'});
                        }
                    })
                    .catch((error) => {
                        this.$router.push({name: 'customers'});
                    })
            },

            saveArticle() {
                let payload = this.data;

                ApiService.put('articles/' + this.$route.params.id, payload)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.data = response.data.result;
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Das Produkt wurde erfolgreich bearbeitet',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Das Produkt konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Das Produkt konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
            }
        },
    }
</script>


<style lang="scss">
    .product-details {
        .quillWrapper {
            .ql-editor {
                //height: 7rem;
                min-height: 6rem;
            }
        }
    }
</style>